import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import ItemForm from '../../Components/Forms/ItemForm';
import Api from '../../Lib/Api';

const Component = (props) => {
  const [adminNotedItems, setAdminNotedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editItemModalActive, setEditItemModalActive] = useState(false);
  const [editItem, setEditItem] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    props.getItems({ adminNotes: true }).then((action) => {
      setAdminNotedItems(action.payload.data);
      setIsLoading(false);
    })
  }

  const onEditModalClose = () => {
    getData();
    setEditItemModalActive(false);
    setEditItem(null);
  }

  const onEditClicked = (item) => {
    setEditItem(item);
    setEditItemModalActive(true);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Items with Admin Notes Report</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Admin Note</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {isLoading ? (<tr><td colspan="3" scope="row">Loading Report</td></tr>) : adminNotedItems.map((i) => (
                  <tr key={i.id}>
                    <td scope="row">{i.name}</td>
                    <td>{i.admin_note}</td>
                    <td>
                      <div className="btn" onClick={() => onEditClicked(i)}>Edit</div>
                    </td>
                  </tr>
                ))}
                {!isLoading && adminNotedItems.length === 0 && (<tr><td colspan="3" scope="row">No items with admin notes found.</td></tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={editItemModalActive}
        onHide={onEditModalClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <ItemForm
          item={editItem}
          onSubmit={onEditModalClose}
          onCancel={onEditModalClose}
        />
      </Modal>
    </main>
  )
}

const mapStateToProps = state => ({
  adminSettings: state.adminSettings
})
const mapDispatchToProps = {
  getItems: Api.getItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);