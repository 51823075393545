import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Store from '../Lib/Store';
import Api from "../Lib/Api";
import { toast } from "react-toastify";
import AnimatedButton from "./AnimatedButton";
import { getMealLabel } from "../Lib/Util";

const Cart = (props) => {
  const history = useHistory();

  const cart = CartManager.getCart();
  const [note, setNote] = useState('');
  const [isNoteVisible, setNoteVisible] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isButtonsEnabled, setIsButtonsEnabled] = useState(true);

  useEffect(() => {
    const items = props.items.map(i => i.id)
    cart.filter(i => items.includes(i.id));
    CartManager.saveCart(cart);
    setIsLoading(false);
  }, []);

  useEffect(() => {
      // Temporarily add a class to the Cart to highlight that something changed then remove it 1s later
      if (!isLoading) {
        if (cart.length === 0) { setCartOpen(false); }
        else { setCartOpen(true); }
      }
  }, [cart.length]);

  const onChange = (item, qty) => {
    const quantity = parseInt(qty, 10);
    const actualItem = props.items.find(i => i.id === item.id);

    if (actualItem.quantity_available < quantity) {
      CartManager.addItem(item, actualItem.quantity_available);
    } else {
      CartManager.addItem(item, quantity);
    }
  };

  const onBlur = (item, qty) => {
    const quantity = parseInt(qty, 10);
    if (quantity === 0) {
      CartManager.removeItem(item);
    }
  };

  const onConfirm = () => {
    setIsButtonsEnabled(false);
    setIsConfirming(true);
    setTimeout(() => {
      setIsButtonsEnabled(true);
    }, 1000)
  }

  const onSubmit = () => {
    setIsLoading(true);
    props.createOrder({
      note,
      items: CartManager.getCart(),
    }).then((action) => {
      if (action.error) {
        if (action.error.response.status === 422) {
          toast.error('Requesting too many items.')
        } else {
          toast.error('Error Creating')
        }
        return;
      }
      toast.success('Order Submitted.')
      props.getOrders();
      props.getItems();
      setIsLoading(false);
      CartManager.clearCart();
      history.push('/history');
    });
  }

  return (
    <div className={isCartOpen ? 'cart is-active' : 'cart is-inactive'}>
      <div onClick={() => setCartOpen(!isCartOpen)} className="cart-heading">
        <div className="cart-icon">
          <div className="item-count">{cart.length}</div>
        </div>
        <h4>{cart.length == 1 ? 'Item' : 'Items'}</h4>
      </div>
      <div className="cart-body">
        {cart.length < 1 && (<div className="mb-20">Your cart is empty.</div>)}
        <ul>
          {cart.map(item => (
            <li key={`${item.id}${item.sub && `-${item.sub.type}`}`}>
              <div className="item-name">{item.name}</div>
              {item.sub && (
                <div className="item-meal-sizes">
                  {Object.entries(item.sub).map(([key, value]) => (
                    <div key={key} className="item-meal-size">
                      <div className="item-meal-size-name">{getMealLabel(key, true)}</div>
                      <div className="d-flex align-items-center">
                        <div className="item-meal-size-quantity">{value}</div>
                        {!isConfirming && (
                          <OverlayTrigger
                            placement="bottom"
                            trigger={["hover", 'focus']}
                            overlay={
                              <Tooltip>Remove item from cart</Tooltip>
                            }
                          >
                            <span className="item-remove" onClick={() => CartManager.removeItem(item, key) }>&times;</span>
                          </OverlayTrigger>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!item.sub && (
                <div className="d-flex align-items-center">
                  <div className="item-quantity">{item.quantity_requested}</div>
                  {!isConfirming && (
                    <OverlayTrigger
                      placement="bottom"
                      trigger={["hover", 'focus']}
                      overlay={
                        <Tooltip>Remove item from cart</Tooltip>
                      }
                    >
                      <span className="item-remove" onClick={() => CartManager.removeItem(item) }>&times;</span>
                    </OverlayTrigger>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
        {isNoteVisible ? (
          <input value={note} onChange={e => setNote(e.target.value)} placeholder="Write a short note" className="mb-20" />
        ) : (
          <div className="link mb-20" onClick={() => setNoteVisible(true)}>Add a Note</div>
        )}

        {isConfirming && ( <div className="mb-5"><strong>Does your cart look correct?</strong></div>)}
        <div className="d-flex gap-3">
          {isConfirming && ( <AnimatedButton disabled={!isButtonsEnabled || cart.length < 1} className="btn btn-outline mb-0" onClick={() => setIsConfirming(false)}>Edit Cart</AnimatedButton>)}
          <AnimatedButton disabled={!isButtonsEnabled || cart.length < 1} className="btn mb-0" onClick={isConfirming ? onSubmit : onConfirm}>{isConfirming ? 'Complete Checkout' : 'Checkout'}</AnimatedButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  items: state.items,
  cart: state.cart,
});

const mapDispatchToProps = {
  createOrder: Api.createOrder,
  getOrders: Api.getOrders,
  getItems: Api.getItems,
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);

class CM {
  constructor() {
    if (!sessionStorage.getItem('cart')) sessionStorage.setItem('cart', '[]');
  }

  getCart = () => JSON.parse(sessionStorage.getItem('cart'));
  saveCart = (cart) => {
    Store.dispatch({ type: 'UPDATE_CART', payload: cart });
    sessionStorage.setItem('cart', JSON.stringify(cart));
  }

  addItem = (item, qty) => {
    let cart = this.getCart();

    const cartItem = cart.find(i => i.id === item.id);
    if (!cartItem) {
      const newItem = { ...item };
      if (item.sub) {
        newItem.sub = { [item.sub]: qty };
      } else {
        newItem.quantity_requested = qty;
      }
      cart.push(newItem)
    } else {
      if (item.sub) {
        cart = cart.reduce((c, i) => {
          if (i.id === item.id) i.sub[item.sub] = qty;
          c.push(i);
          return c;
        }, []);
      } else {
        cart = cart.reduce((c, i) => {
          if (i.id === item.id) i.quantity_requested = qty;
          c.push(i);
          return c;
        }, []);
      }

    }

    this.saveCart(cart);
  };

  removeItem = (item, subitem) => {
    let cart = this.getCart();

    if (subitem) {
      cart = cart.reduce((c, i) => {
        console.log(i, item, subitem);
        if (i.id === item.id) {
          delete i.sub[subitem];
          // If there are no more subitems, remove the item
          if (Object.keys(i.sub).length === 0) return c;
          c.push(i);
        } else {
          c.push(i);
        }
        return c;
      }, []);
    } else {
      cart = cart.filter(i => i.id !== item.id);
    }

    this.saveCart(cart);
  };

  clearCart = () => {
    this.saveCart([]);
  }
}

export const CartManager = new CM();
