import { useEffect } from 'react';

export default function useKeyPress(targetKey, up, down=()=>{}) {
  const upHandler = ({ keyCode }) => { if (keyCode === targetKey) { up(); } };
  const downHandler = ({ keyCode }) => { if (keyCode === targetKey) { down(); } }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
}