import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';


import InventoryList from '../Components/InventoryList';
import ItemForm from '../Components/Forms/ItemForm';
import MarkAsForm from '../Components/Forms/MarkAsForm';
import RequiresAdmin from '../Components/RequiresAdmin';
import Cart from '../Components/Cart';

import iconPickupTimes from '../Images/icon-pickup-times.svg';

const Component = (props) => {
  const [newItemModalActive, setNewItemModalActive] = useState(false);
  const [editItemModalActive, setEditItemModalActive] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [markAsModalActive, setMarkAsModalActive] = useState(false);
  const [markedAsItem, setMarkedItem] = useState(false);

  const onAddItemClick = () => { setNewItemModalActive(true); }

  const sectionedItems = props.items.reduce((list, i) => {
    if (parseInt(i.quantity_available,10) <= 0) return {...list}; // No More Available
    if (i.category === 'meals') {
      const totalQty = (+i.container_16oz + +i.container_32oz + +i.container_family);
      if (totalQty <= 0) return {...list};
    }

    if (i.is_on_hold) {  // Is on Hold
      list.hold.items.push(i);
      return {...list};
    }

    if (!list[i.category]) {
      list[i.category] = { label: i.category, items: [] }
    }

    list[i.category].items.push(i);

    return {...list};
  }, { hold: { label: 'on hold', items: [] } });

  const onEditModalClose = () => {
    setEditItemModalActive(false);
    setEditItem(null);
  }

  const onEditClicked = (item) => {
    setEditItem(item);
    setEditItemModalActive(true);
  }

  const onMarkAsModalClose = () => {
    setMarkAsModalActive(false);
    setEditItem(null);
  }

  const onMarkAsClicked = (item) => {
    setMarkedItem(item);
    setMarkAsModalActive(true);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Inventory</h1>
                <div className="page-action">
                  <RequiresAdmin><div className="btn" onClick={onAddItemClick}>Add Item</div></RequiresAdmin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="pickup-times">
                <h3><img src={iconPickupTimes} alt="pickup times" className="icon-pickup-times" /><span>Pickup Times:</span></h3>
                <div className="pickup-times-content">
                  <strong>{props.adminSettings.pickup_time || 'Any Time'}</strong>
                  <RequiresAdmin><Link className="btn btn-outline btn-sm" to="/distribution">Edit</Link></RequiresAdmin>
                </div>
                <br/>
                <i>{props.adminSettings.extra_text}</i>
              </div>
            </div>
          </div>
        </div>
      </section>

      {Object.values(sectionedItems).filter(l => l.label !== 'on hold').length < 1 && (
        <div className="simple-container subtle">
          There are no published items to show.
        </div>
      )}

      {/* Section for meals */}
      {sectionedItems.meals?.items.length > 0 && (
        <InventoryList
          key={sectionedItems.meals.label}
          {...sectionedItems.meals}
          onEditClicked={onEditClicked}
          onMarkAsClicked={onMarkAsClicked}
        />
      )}

      {/* Section for everything else */}
      {Object.values(sectionedItems).filter(l => l.label !== 'on hold' && l.label !== 'meals').sort((a, b) => a.label <= b.label ? 1 : -1).map(section => (
        <InventoryList
          key={section.label}
          {...section}
          onEditClicked={onEditClicked}
          onMarkAsClicked={onMarkAsClicked}
        />
      ))}

      {/* Section for on hold */}
      {props.user.is_admin && sectionedItems.hold.items.length > 0 && (
        <InventoryList
          key={sectionedItems.hold.label}
          {...sectionedItems.hold}
          onEditClicked={onEditClicked}
          onMarkAsClicked={onMarkAsClicked}
        />
      )}

      {props.options.kiosk && (
        <RequiresAdmin reverse><Cart /></RequiresAdmin>
      )}
      

      <Modal
        show={newItemModalActive}
        onHide={() => setNewItemModalActive(false)}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <ItemForm
          onSubmit={() => setNewItemModalActive(false)}
          onCancel={() => setNewItemModalActive(false)}
        />
      </Modal>

      <Modal
        show={editItemModalActive}
        onHide={onEditModalClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <ItemForm
          item={editItem}
          onSubmit={onEditModalClose}
          onCancel={onEditModalClose}
        />
      </Modal>

      <Modal
        show={markAsModalActive}
        onHide={onMarkAsModalClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Item as...</Modal.Title>
        </Modal.Header>
        <MarkAsForm
          item={markedAsItem}
          onSubmit={onMarkAsModalClose}
          onCancel={onMarkAsModalClose}
        />
      </Modal>
    </main>
  )
}

const mapStateToProps = state => ({
  items: state.items,
  user: state.user,
  options: state.options,
  adminSettings: state.adminSettings,
});

export default connect(mapStateToProps)(Component);
