import React from 'react';

import { Table } from 'react-bootstrap';

export default () => (
  <Table striped className="conversion-table">
    <thead>
      <tr>
        <td>Ounce</td>
        <td>Pound</td>
      </tr>
    </thead>
    <tbody>
      {Array.from({ length: 16 }, (v, k) => k + 1).map(n => (
        <tr>
          <td>{n} oz</td>
          <td>{n/16} lb</td>
        </tr>
      ))}
      </tbody>
  </Table>
);
