import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../../Lib/Api';
import useKeyPress from '../../Hooks/useKeyPress';
import AnimatedButton from '../AnimatedButton';


const Component = (props) => {
  const [name, setName] = useState(props.organization ? props.organization.name : '');
  const [isLoading, setIsLoading] = useState(false);

  useKeyPress(27, () => { if (props.onCancel) props.onCancel(); });

  const isValid = () => {
    if (!name || name.length === 0) return false;

    return true;
  }

  const deleteOrganization = () => {
    if (confirm(`Are you sure you want to delete the ${name} organization? This will also delete all organization users.`)) {
      if (confirm('This can not be undone. Are you sure?')) {
        props.deleteOrganization(props.organization.id).then(() => {
          props.getOrganizations();
          if (props.onDelete) props.onDelete();
        });
      }
    }
  }

  const submit = () => {
    if (!isValid()) {
      toast.error('New organizations require "Name" to be filled out.');
      return;
    }
    setIsLoading(true);

    if (props.organization) {
      props.editOrganization({
        id: props.organization.id,
        name,
      }).then(() => {
        props.getOrganizations();
        setIsLoading(false);
        if (props.onSubmit) props.onSubmit();
      });
    } else {
      props.createOrganization({
        name,
      }).then(() => {
        props.getOrganizations();
        setIsLoading(false);
        if (props.onSubmit) props.onSubmit();
      }).catch(() => {
        toast.error('error creating.')
      });
    }
  }

  return (
    <div>
      <Form className="add-item-form">
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control placeholder="Food Recovery" value={name} onChange={e => setName(e.target.value)} />
        </Form.Group>

        <div className="btn-group">
          <AnimatedButton isLoading={isLoading} onClick={() => submit()} className="btn">Save</AnimatedButton>
          <div className="btn btn-outline" onClick={props.onCancel}>Cancel</div>
        </div>
        {props.organization && props.organization.id && <div className="btn btn-danger float-end mb-0" onClick={() => deleteOrganization()}>Delete</div>}
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  createOrganization: Api.createOrganization,
  getOrganizations: Api.getOrganizations,
  editOrganization: Api.editOrganization,
  deleteOrganization: Api.deleteOrganization,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
