import React from 'react';
import ReactDom from 'react-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-select-2/dist/css/react-select-2.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/Application.scss';

import Application from './App';

ReactDom.render(<Application />, document.getElementById('application'));
