class Auth {
  storeToken = (token) => {
    localStorage.setItem('token', token);
  }

  storeUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  }

  logout = (callback) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  isLoggedIn = () => {
    const token = localStorage.getItem('token');
    return !!token && token !== undefined && token !== null && token !== 'undefined' && token !== 'null';
  }

  getBearerToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    return `Bearer ${token}`;
  }

  getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    return token;
  }
}

export default new Auth();