import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) { return { hasError: true }; }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="error-boundary-inner">
            <h2>Something went wrong.</h2>
            <button type="button" onClick={() => window.location.reload()} className="btn btn-primary btn-medium">Refresh the Page</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
