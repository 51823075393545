import React, { useRef, useState } from 'react';
import {Overlay, Popover, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from "react-redux";

import Store from '../Lib/Store';
import { CartManager } from './Cart';
import FormattedTime from './FormattedTime';
import RequiresAdmin from './RequiresAdmin';
import { getMealLabel } from '../Lib/Util';

const conditions = {
  fresh: 'Fresh',
  frozen: 'Frozen',
  dry_good: 'Dry Good',
};

const InventoryItem = ({ item, onEditClicked, onMarkAsClicked, options, locations, user }) => {
  const [quantityRequested, setQuantityRequested] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [subitemType, setSubitemType] = useState(null);

  const popoverTargetRef = useRef(null);
  const containerRef = useRef(null);
  const addToCartInputRef = useRef(null);

  const addToCart = () => {
    const qty = parseInt(quantityRequested, 10);
    const available = subitemType ? item[subitemType] : item.quantity_available;
    if (qty === 0) return;
    if (qty > available) { toast.error('Requesting too many items.'); return; }
    CartManager.addItem({ id: item.id, name: item.name, sub: subitemType }, qty);
    setQuantityRequested("");
    setShowPopover(false);
  }

  const closePopover = () => {
    setShowPopover(false);
  }

  const openPopover = React.useCallback((sub) => {
    setShowPopover(true);
    if (sub) {
      setSubitemType(sub);
    }
  }, [subitemType]);

  return (
    <div className="food-item">
      <div className="food-item-content">
        <div className="item-name-description">
          <div className="item-name">{item.name}</div>
          <div className="item-description">{item.note}</div>
        </div>
        <div className="item-location">{item.location_id && locations && locations.find(l => l.id === item.location_id).name}</div>
        <div className="item-condition">{conditions[item.condition] || item.condition} <span className="d-lg-none">(condition)</span></div>
        <div className="item-use-by"><span className="d-lg-none">Pick up by </span><FormattedTime time={item.use_by_date} /></div>
        {item.category !== 'meals' && (<div className="item-unit-weight">{`${item.weight_per_unit}lb ${item.unit}`}</div>)}
        {item.category !== 'meals' && (<div className="item-units-available">{item.quantity_available} <span className="d-lg-none">Available</span></div>)}
        {item.category === 'meals' &&
          <div className={user.is_admin ? 'meal-item-list admin' : 'meal-item-list non-admin'}>
            { ['container_16oz','container_32oz','container_family'].map(v => {
              if (item[v] <= 0) return null;
              return (
              <div className="meal-item" key={v}>
                <div className="meal-item-info">
                  <div className="meal-item-name">{getMealLabel(`${v}`)}</div>
                  <div className="meal-item-quantity">{item[v]} Available</div>
                </div>
                {options.kiosk && (
                  <RequiresAdmin reverse>
                    <div>
                      <div ref={popoverTargetRef} className="btn btn-outline" onClick={() => openPopover(v)}>Select</div>
                    </div>
                  </RequiresAdmin>
                )}
              </div>
            )})}
          </div>
        }

        <div className="item-actions" ref={containerRef}>
          <RequiresAdmin>
            <div className="btn btn-outline" onClick={() => onEditClicked(item)}>Edit</div>
            <div className="btn" onClick={() => onMarkAsClicked(item)}>Mark as...</div>
          </RequiresAdmin>
          {options.kiosk && item.category !== 'meals' && (
            <RequiresAdmin reverse>
              <div ref={popoverTargetRef} className="btn btn-outline" onClick={() => openPopover()}>Select</div>
            </RequiresAdmin>
          )}
        </div>
      </div>
      <Overlay target={popoverTargetRef.current} show={showPopover} placement="top" container={containerRef.current}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <Popover {...props} id="popover-basic" style={props.style}>
            <Popover.Body>
              <h3 className="popover-heading">{item.name}</h3>
              <div>
                <strong>{subitemType ? getMealLabel(`${subitemType}`) : 'Amount Picking Up'}</strong><br />
                <div className="mb-10">({subitemType ? item[subitemType] : item.quantity_available} available)</div>
              </div>
              <Form.Group>
                <input autoFocus className="form-control" type="number" value={quantityRequested} min={0} max={subitemType ? item[subitemType] : item.quantity_available} onChange={e => setQuantityRequested(e.target.value || "")} />
                <button className="btn" disabled={quantityRequested <= 0 || quantityRequested > item[subitemType]} onClick={() => {addToCart()}}>Add to Cart</button>
                <div className="popover-close" onClick={() => {closePopover()}}>Close</div>
              </Form.Group>
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    locations: state.locations,
    user: state.user,
  };
}

export default connect(mapStateToProps)(InventoryItem);