import React from 'react';

export default ({ user, org, onEditUserClicked }) => {
  return (
    <div className="organization-item">
      <div className="organization-item-content">
        <div className="item-name-description">
          {console.log(user.is_admin)}
          <div className="item-name">{user.name}{user.is_admin && (
            <span title="Admin">{" "}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge-fill" viewBox="0 0 16 16">
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"/>
              </svg>
            </span>
          )}</div>
          {user.is_org_primary && <div className="item-primary">(Primary Contact)</div>}
        </div>
        <div className="item-email"><a href={`mailto:${user.email}`} target="_blank">{user.email}</a></div>
        <div className="item-phone"><a href={`tel:${user.phone}`} target="_blank">{user.phone}</a></div>
        <div className="item-is-subbed"><span className="d-none d-lg-block">{user.is_weekly_subscribed ? 'Yes' : 'No'}</span> <span className="d-lg-none">{user.is_weekly_subscribed ? 'Subscribed' : 'Not Subscribed'}</span></div>
        <div className="item-actions">
          <div className="link pt-5 pb-5" onClick={() => onEditUserClicked(org, user)}>Edit</div>
        </div>
      </div>
    </div>
  );
}