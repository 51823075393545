import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../Lib/Api';
import useKeyPress from '../Hooks/useKeyPress';
import Action from '../Lib/Action';
import Auth from '../Lib/Auth';
import AnimatedButton from '../Components/AnimatedButton';

const Component = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (Auth.isLoggedIn()) { props.history.push('/') }

  const onLoginClick = () => {
    setIsLoading(true);
    props.authenticate({ email, password }).then((action) => {
      setIsLoading(false);
      if (action.type === Action.AUTHENTICATE_SUCCESS) {
        props.history.push('/');
      } else if (action.error && action.error.response.status === 403) {
        toast.error('Incorrect Email/Password. Please try again.')
      }
    });
  };

  const onKeyPress = ({ key }) => {
    if (key === "Enter") onLoginClick();
  };

  return (
    <main>
      <section>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <h2 className="mt-50">Login</h2>
              <form className="authentication-form">
                <label className="form-field">
                  <div>Email</div>
                  <input
                    type="email"
                    autoComplete="email"
                    id="auth-email"
                    placeholder="you@domain.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyPress={onKeyPress}
                  />
                </label>
                <label className="form-field">
                  <div>Password</div>
                  <input
                    type="password"
                    autoComplete="current-password"
                    id="auth-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyPress={onKeyPress}
                  />
                </label>
                <div className="form-field">
                  <div className="link" onClick={() => props.history.push('forgot-password')}>Forgot Password?</div>
                </div>
                <div className="form-field">
                  <AnimatedButton isLoading={isLoading} onClick={onLoginClick} className="btn">Login</AnimatedButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  authenticate: Api.authenticate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
