import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../../Lib/Api';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import ItemForm from '../../Components/Forms/ItemForm';

const Component = (props) => {
  const [expiredItems, setExpiredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editItemModalActive, setEditItemModalActive] = useState(false);
  const [editItem, setEditItem] = useState(false);

  const getData = () => {
    props.getItems({ expired: true }).then((action) => {
      setExpiredItems(action.payload.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onEditModalClose = () => {
    getData();
    setEditItemModalActive(false);
    setEditItem(null);
  }

  const onEditClicked = (item) => {
    setEditItem(item);
    setEditItemModalActive(true);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Expired Items Report</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Condition</th>
                  <th scope="col">Location</th>
                  <th scope="col">Pick Up By Date</th>
                  <th scope="col">Quantity Left</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {isLoading ? (<tr><td scope="row">Loading Report</td></tr>) : expiredItems.map((i) => (
                  <tr key={i.id}>
                    <td scope="row">{i.name}</td>
                    <td>{i.condition}</td>
                    <td>{i.location}</td>
                    <td>{moment.tz(i.use_by_date, moment.tz.guess()).format('MM/DD/yyyy')}</td>
                    <td>{i.quantity_available}</td>
                    <td>
                      <div onClick={() => onEditClicked(i)}>Edit</div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          show={editItemModalActive}
          onHide={onEditModalClose}
          backdrop="static"
          keyboard={false}
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <ItemForm
            item={editItem}
            onSubmit={onEditModalClose}
            onCancel={onEditModalClose}
          />
        </Modal>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
  adminSettings: state.adminSettings
})
const mapDispatchToProps = {
  getItems: Api.getItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);