import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import OrganizationForm from '../Components/Forms/OrganizationForm';
import UserForm from '../Components/Forms/UserForm';
import UserList from '../Components/UserList';

const Component = (props) => {
  const [isOrgModalOpen, openOrgModal] = useState(false);
  const [isUserModalOpen, openUserModal] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [user, setUser] = useState(false);


  const onEditUserClicked = (org, user) => {
    setOrganization(org);
    setUser(user);
    openUserModal(true);
  };

  const onEditOrganizationClicked = (org) => {
    setOrganization(org);
    openOrgModal(true);
  };

  const onAddUserClicked = (org) => {
    setOrganization(org);
    openUserModal(true);
  };

  const onNewOrganizationClicked = () => {
    openOrgModal(true);
  };

  const closeModals = () => {
    openOrgModal(false);
    openUserModal(false);
    setOrganization(null);
    setUser(null)
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Organizations</h1>
                <div className="page-action">
                  <div className="btn" onClick={onNewOrganizationClicked}>Add Organization</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        {props.organizations.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(org => (
          <UserList
            key={org.id}
            organization={org}
            onAddUserClicked={onAddUserClicked}
            onEditOrganizationClicked={onEditOrganizationClicked}
            onEditUserClicked={onEditUserClicked}
          />
        ))}

        <Modal
          show={isOrgModalOpen}
          onHide={closeModals}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrganizationForm
              onSubmit={closeModals}
              onCancel={closeModals}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={isUserModalOpen}
          onHide={closeModals}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserForm
              organization={organization}
              onSubmit={closeModals}
              onCancel={closeModals}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={isOrgModalOpen}
          onHide={closeModals}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Edit Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrganizationForm
              organization={organization}
              onSubmit={closeModals}
              onCancel={closeModals}
              onDelete={closeModals}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={isUserModalOpen}
          onHide={closeModals}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserForm
              user={user}
              organization={organization}
              onSubmit={closeModals}
              onCancel={closeModals}
              onDelete={closeModals}
            />
          </Modal.Body>
        </Modal>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
  organizations: state.organizations,
})

export default connect(mapStateToProps)(Component);
