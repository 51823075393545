import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../Lib/Api';
import { Modal } from 'react-bootstrap';

const Component = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editLocationModalActive, setEditLocationModalActive] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const getData = () => {
    props.getLocations().then((action) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = () => {
    props.editLocation(editLocation).then((action) => {
      onEditModalClose();
    });
  }

  const handleDelete = () => {
    if (!isConfirmingDelete) {
      setIsConfirmingDelete(true);
      return;
    } else {
      props.deleteLocation(editLocation.id).then((action) => {
        onEditModalClose();
      });
    }
  }

  const onEditModalClose = () => {
    getData();
    setEditLocationModalActive(false);
    setEditLocation(null);
  }

  const onEditClicked = (item) => {
    setEditLocation(item);
    setEditLocationModalActive(true);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Locations List</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Location</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {!isLoading && props.locations.length === 0 && (
                  <tr><td scope="row">No Locations Found</td></tr>
                )}
                {isLoading ? (<tr><td scope="row">Loading Report</td></tr>) : props.locations.map((l) => (
                  <tr key={l.id}>
                    <td scope="row">{l.name}</td>
                    <td>
                      <div onClick={() => onEditClicked(l)}>Edit</div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          show={editLocationModalActive}
          onHide={onEditModalClose}
          backdrop="static"
          keyboard={false}
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                
                <label htmlFor="name">Location Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  defaultValue={editLocation?.name}
                  onChange={(e) => setEditLocation({ ...editLocation, name: e.target.value })}
                />
              </div>
              <div
                className="btn btn-primary mr-10"
                onClick={() => handleSubmit()}
              >
                Submit
              </div>

              <div
                className="btn btn-danger"
                onClick={() => handleDelete()}
              >
                {isConfirmingDelete ? 'Are You Sure?' : 'Delete'}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
  locations: state.locations
})
const mapDispatchToProps = {
  getLocations: Api.getLocations,
  editLocation: Api.editLocation,
  deleteLocation: Api.deleteLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);