import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedButton from '../Components/AnimatedButton';
import RequiresAdmin from '../Components/RequiresAdmin';
import Api from '../Lib/Api';

const Component =  ({ adminSettings, setSettings, getSettings, sendDistributionEmail }) => {
  const [isEditingPickupTime, setEditingPickupTime] = useState(false);
  const [isSavingPickupTime, setIsSavingPickupTime] = useState(false);
  const [pickupTime, setPickupTime] = useState(adminSettings.pickup_time ?? '');

  const [isEditingExtraText, setEditingExtraText] = useState(false);
  const [isSavingExtraText, setIsSavingExtraText] = useState(false);
  const [extraText, setExtraText] = useState(adminSettings.extra_text ?? '');

  useEffect(() => {
    setPickupTime(adminSettings.pickup_time ?? '') 
    setExtraText(adminSettings.extra_text ?? '')
  }, [adminSettings]);

  const onEditPickupTime = () => {
    setEditingPickupTime(true);
  }

  const onSavePickupTime = () => {
    setIsSavingPickupTime(true);
    setSettings({ key: 'pickup_time', value: pickupTime }).then(() => {
      getSettings().then(() => {
        setEditingPickupTime(false);
        setIsSavingPickupTime(false);
      });
    });
  }

  const onEditExtraText = () => {
    setEditingExtraText(true);
  }

  const onSaveExtraText = () => {
    setIsSavingExtraText(true);
    setSettings({ key: 'extra_text', value: extraText }).then(() => {
      getSettings().then(() => {
        setEditingExtraText(false);
        setIsSavingExtraText(false);
      });
    });
  }

  const previewEmail = () => {
    sendDistributionEmail({ preview: true }).then((res) => {
      toast.success('Preview email sent.');
    });
  }

  const sendEmail = () => {
    if (confirm('Are you sure you want to send the distribution email?')) {
      sendDistributionEmail().then((res) => {
        console.log(res);
        if (res.payload.data.sent) {
          toast.success('Distribution Report email sent.');
        } else {
          toast.error('Distribution Report email not sent. Reason: ' + res.payload.data.reason);
        }
      });
    }
  }

  return (
    <main>
      <RequiresAdmin reverse><Redirect to="/" /></RequiresAdmin>

      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Distribution</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
          <div className="col-12">
              {isEditingPickupTime ? (
                <div>
                  <Form.Label><strong>Current pickup times:</strong></Form.Label>
                  <Form.Control value={pickupTime} onChange={(e) => setPickupTime(e.target.value)} />
                  <AnimatedButton isLoading={isSavingPickupTime} onClick={onSavePickupTime} className="btn">Save</AnimatedButton>
                </div>
              ) : (
              <div>
                <div><strong>Current pickup times:</strong></div>
                {adminSettings.pickup_time || 'Pickup times not set'}
                &nbsp;
                <div onClick={onEditPickupTime} className="link inline">Edit</div>
              </div>
              )}
            </div>

            <div className="col-12">
              {isEditingExtraText ? (
                <div>
                  <Form.Label><strong>Additional Information:</strong></Form.Label>
                  <Form.Control value={extraText} onChange={(e) => setExtraText(e.target.value)} />
                  <AnimatedButton isLoading={isSavingExtraText} onClick={onSaveExtraText} className="btn">Save</AnimatedButton>
                </div>
              ) : (
              <div>
                <div><strong>Additional Information:</strong></div>
                {adminSettings.extra_text || 'not set'}
                &nbsp;
                <div onClick={onEditExtraText} className="link inline">Edit</div>
              </div>
              )}
            </div>
          </div>
          <div className="row max-600 mt-50">
            <div className="col-12 col-md-6">
              <span className="btn" onClick={previewEmail}>Send Myself a Preview Email</span>
            </div>
            <div className="col-12 col-md-6">
              <span className="btn" onClick={sendEmail}>Publish Distribution Email</span>
            </div>
          </div>


        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = state => ({
  adminSettings: state.adminSettings
})
const mapDispatchToProps = {
  setSettings: Api.setSettings,
  getSettings: Api.getSettings,
  sendDistributionEmail: Api.sendDistributionEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
