import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Auth from '../Lib/Auth';
import RequiresAdmin from './RequiresAdmin';
import logoMark from '../Images/mnfr-logo-mark-state-outline.png';

const Navigation = (props) => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" >
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logoMark} alt="MN Food Recover" className="logo-mark" />
          <div className="logo-text">SCMNFR</div>
        </Navbar.Brand>
        {Auth.isLoggedIn() && (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
            <Nav>
              <Nav.Link className="nav-link" href="/" end>Inventory</Nav.Link>
              <NavLink className="nav-link" to="/history">
                <RequiresAdmin>History</RequiresAdmin>
                <RequiresAdmin reverse>My History</RequiresAdmin>
              </NavLink>
              <RequiresAdmin>
                <NavLink className="nav-link" to="/organizations">Organizations</NavLink>
                <NavLink className="nav-link" to="/admin">Admin Panel</NavLink>
              </RequiresAdmin>
              <Nav.Link onClick={() => Auth.logout()} href="/login">Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>)}
      </Container>
    </Navbar>
  );
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(Navigation);
