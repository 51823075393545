import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FormattedTime from './FormattedTime';
import OrderItem from './OrderItem';

export default (props) => {
  let currentStatus = props.order.completed_at ? 'Complete' : 'Ready for Pickup';
  if (!props.order.completed_at) {
    if (props.order.items.find(i => i.completed_at)) {
      currentStatus = 'Partially Complete';
    }
  }

  return (
    <section className="request-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10">
            <h3 className="mb-0">{props.order.organization.name}</h3>
            <OverlayTrigger
              placement="top"
              trigger="focus"
              overlay={
                <Tooltip>#{parseInt(props.order.id.replace(/-/g, ''), 16).toString(36).replace(/0*$/, '')}</Tooltip>
              }
            >
              <h4>Picked up on <FormattedTime time={props.order.created_at} /> by {props.order.user.name}</h4>
            </OverlayTrigger>
            <div className="page-action">
              <div className="link inline-block mb-20" onClick={() => props.onEditOrderClicked(props.order)}>Edit Order Note</div>
            </div>
            {props.order.note && (<p>Order Note: {props.order.note}</p>)}
          </div>
          <div className="col-md-2"><div className="status-pill">{currentStatus}</div></div>
          <div className="col-12">
          <div className="category-container">

            <div className="food-item column-headings">
                <div className="food-item-content">
                  <div className="item-name-description">Name
                  </div>
                  <div className="item-unit-weight">Quantity Received</div>
                  <div className="item-actions"></div>
                </div>
              </div>

              {props.order.items.map(item => (
                <OrderItem
                  key={item.id}
                  item={item}
                  onMarkAsClicked={props.onMarkAsClicked}
                />
              ))}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}