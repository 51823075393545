import React, { useState } from 'react';

export default ({ item, onMarkAsClicked }) => {
  const [received, setReceived] = useState((item.quantity_received || item.quantity_requested || 0).toString());
  const [offReason, setOffReason] = useState('');

  const receivedCount = parseInt(received, 10);

  return (
    <div className="food-item">
      <div className="food-item-content">
        <div className="item-name-description">
          <div className="item-name">{item.name}</div>
          <div className="item-description">{item.note}</div>
        </div>
        {item.category === 'meals' ? (<>
          <div className="item-units-available">16 oz Containers: {item.container_16oz_received || 0} <span className="d-lg-none">Taken</span></div>
          <div className="item-units-available">32 oz Containers: {item.container_32oz_received || 0} <span className="d-lg-none">Taken</span></div>
          <div className="item-units-available">Family Containers: {item.container_family_received || 0} <span className="d-lg-none">Taken</span></div>
        </>) : (
          <div className="item-units-available">{item.quantity_requested} <span className="d-lg-none">Received</span></div>
        )}
        <div className="item-actions">
          {item.completed_at ? (
            <div className="fulfilled">&#x2713; Picked Up</div>
          ) : (
            <div className="btn" onClick={() => onMarkAsClicked(item, receivedCount, offReason)}>Mark as fulfilled</div>
          )}
        </div>
      </div>
      {item.quantity_requested > receivedCount &&
        <div className="pickup-quantity-reason">
          {item.completed_at ? (
            <div className="value-why-less"><strong>{item.order_note? "Reason: ":""}</strong> {item.order_note}</div>
          ) : (
            <input placeholder="Why is quantities requested and received different?" className="form-control input-why-less" value={offReason} onChange={e => setOffReason(e.target.value)} />
          )}
        </div>
      }
    </div>
  );
}