import React from 'react';
import UserItem from './UserItem';

export default (props) => {
  return (
    <section className="category-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="heading-with-actions">
              <h3>{props.organization.name}</h3>
              <div className="actions">
                <div className="btn btn-outline" onClick={() => props.onEditOrganizationClicked(props.organization)}>Edit Organization</div>
                <div className="btn btn-outline" onClick={() => props.onAddUserClicked(props.organization)}>Add User</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="category-container">

              {props.organization.users.length > 0 && (
                <div className="organization-item column-headings">
                  <div className="organization-item-content">
                    <div className="item-name-description">Name</div>
                    <div className="item-email">Email</div>
                    <div className="item-phone">Phone</div>
                    <div className="item-is-subbed">Subscribed to messaging</div>
                    <div className="item-actions"></div>
                  </div>
                </div>
              )}

              {props.organization.users.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user => (
                <UserItem
                  key={user.id}
                  user={user}
                  org={props.organization}
                  onEditUserClicked={props.onEditUserClicked}
                />
              ))}

              {props.organization.users.length < 1 && (
                <div className="no-users">This organization doesn't have any users</div>
              )}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}