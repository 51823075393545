import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Api from '../Lib/Api';
import Auth from '../Lib/Auth';

const MINUTE = 60 * 1000;

const Component = (props) => {
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (!Auth.isLoggedIn()) return null;
    props.refreshToken();
    setInterval(() => { setLastUpdated(new Date()) }, MINUTE)
  }, []);

  useEffect(() => {
    if (!Auth.isLoggedIn()) return null;
    Promise.all([
      props.getOrganizations(),
      props.getItems(),
      props.getOrders(),
      props.getSuppliers(),
      props.getLocations(),
      props.getSettings(),
    ]);
  }, [props.user, lastUpdated]);


  return null;
}

const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {
  getOrganizations: Api.getOrganizations,
  getItems: Api.getItems,
  getSuppliers: Api.getSuppliers,
  getLocations: Api.getLocations,
  getOrders: Api.getOrders,
  refreshToken: Api.refreshToken,
  getSettings: Api.getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
