import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Table, OverlayTrigger, Form, Popover, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import { toast } from 'react-toastify';

import Api from '../../Lib/Api';
import useKeyPress from '../../Hooks/useKeyPress';
import AnimatedButton from '../AnimatedButton';
import ConversionTable from '../ConversionTable';
import { getTotalWeightForMeals, getTotalQuantityForMeals } from '../../Lib/Util';

const categoryOptions = [
  { value: 'produce', label: 'Produce' },
  { value: 'meat', label: 'Meat' },
  { value: 'poultry', label: 'Poultry' },
  { value: 'non_meat', label: 'Non-Meat' },
  { value: 'meals', label: 'Meals' },
];

const conditionOptions = [
  { value: 'fresh', label: 'Fresh' },
  { value: 'frozen', label: 'Frozen' },
  { value: 'dry_good', label: 'Dry Good' },
];

const SWAPOptions = [
  { value: 'unranked', label: 'Unranked' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'red', label: 'Red' },
]

let DangerZone = (props) => {
  const Comp = props.item && props.item.published_at ? (props) => (<div {...props} />) : React.Fragment;
  return (
    <Comp className="danger-zone">
      {props.item && props.item.published_at && <p className="danger-message">Changing these values after publishing will affect reporting!</p>}
      {props.children}
    </Comp>
  );
};

const Component = (props) => {
  const [itemType, setItemType] = useState(props.item && props.item.category === 'meals' ? 'meals' : 'single');
  const [name, setName] = useState(props.item ? props.item.name : '');
  const [note, setNote] = useState(props.item ? props.item.note : '');
  const [adminNote, setAdminNote] = useState(props.item ? props.item.admin_note : '');
  const [category, setCategory] = useState(props.item ? categoryOptions.find(c => c.value === props.item.category) : categoryOptions[0]);
  const [swap, setSwap] = useState(props.item ? SWAPOptions.find(o => o.value === props.item.swap_designation) : SWAPOptions[0])
  const [condition, setCondition] = useState(props.item ? conditionOptions.find(c => c.value === props.item.condition) : conditionOptions[0]);
  const [unit, setUnit] = useState(props.item ? props.item.unit : '');
  const [weightPerUnit, setWeightPerUnit] = useState(props.item ? props.item.weight_per_unit : '');
  const [quantityAvailable, setQuantityAvailable] = useState(props.item ? props.item.quantity_available : '');
  const [originalTotalWeight, setOriginalTotalWeight] = useState(props.item ? props.item.original_total_weight : '');
  const [containers16oz, setContainers16oz] = useState(props.item ? props.item.container_16oz : '');
  const [containers32oz, setContainers32oz] = useState(props.item ? props.item.container_32oz : '');
  const [containersFamily, setContainersFamily] = useState(props.item ? props.item.container_family : '');
  const [useByDate, setUseByDate] = useState(props.item ? moment.tz(props.item.use_by_date, moment.tz.guess()).startOf('D').format('yyyy-MM-DD') : '');
  const [isValueAdd, setIsValueAdd] = useState(props.item ? props.item.is_value_add : false);
  const [supplierId, setSupplierId] = useState(null);
  const [isSupplierListLoading, setIsSupplierListLoading] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [isLocationListLoading, setIsLocationListLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isReverting, setIsReverting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useKeyPress(27, () => { if (props.onCancel) props.onCancel(); });

  useEffect(() => {
    if (props.item) {
      const supplier = props.suppliers.find(s => s.id === props.item.supplier_id);
      if (supplier) {
        setSupplierId({ value: supplier.id, label: supplier.name });
      }

      const location = props.locations.find(l => l.id === props.item.location_id);
      if (location) {
        setLocationId({ value: location.id, label: location.name });
      }
    }
  }, [props.item, props.suppliers, props.locations]);

  useEffect(() => {

    if (!props.item) {
      const w = Number(weightPerUnit).toFixed(1);
      const q = parseInt(quantityAvailable, 10);
      if (Number.isNaN(w) || Number.isNaN(q)) { return; }
      setOriginalTotalWeight(Number(w*q).toFixed(1).toString());
    }
  }, [quantityAvailable, weightPerUnit]);


  const isInvalid = () => {
    const invalid = []
    if (!name || name.length === 0) invalid.push('Name');
    if (!category || !category.value) invalid.push('Category');
    if (!useByDate) invalid.push('Pick up By Date');
    if (itemType === 'single') {
      if (!quantityAvailable || parseInt(quantityAvailable, 10) < 0) invalid.push('Units Available');
      if (!weightPerUnit || Number(weightPerUnit) <= 0) invalid.push('Weight Per Unit');
      if (!originalTotalWeight || Number(originalTotalWeight) < 0) invalid.push('Total Weight');
    } else {
      const totalWeight = getTotalWeightForMeals(containers16oz, containers32oz, containersFamily);
      if (totalWeight <= 0) invalid.push('Total Meal Weight');
    }


    return invalid;
  }

  const submit = (isPublishing) => {
    isPublishing ? setIsSubmitting(true) : setIsSaving(true);
    const invalid = isInvalid();
    if (invalid.length > 0) {
      const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
      toast.error(`New items require ${formatter.format(invalid)} to be filled out.`);
      return;
    }

    if (props.item) {
      props.editItem({
        id: props.item.id,
        name,
        note,
        admin_note: adminNote,
        category: category.value,
        swap_designation: swap ? swap.value : null,
        condition: condition.value,
        unit,
        ...itemType === 'meals' ? {
          container_16oz: containers16oz,
          container_32oz: containers32oz,
          container_family: containersFamily,
          original_total_weight: getTotalWeightForMeals(containers16oz, containers32oz, containersFamily),
          weight_per_unit: 16,
          quantity_available: getTotalQuantityForMeals(containers16oz, containers32oz, containersFamily),
        } : {
          weight_per_unit: weightPerUnit,
          quantity_available: quantityAvailable,
          original_total_weight: originalTotalWeight,
        },
        use_by_date: useByDate,
        // supplier_id: supplierId ? supplierId.value : null,
        location_id: locationId ? locationId.value : null,
        published_at: isPublishing && !props.item.published_at ? new Date() : props.item.published_at,
        is_on_hold: !isPublishing,
        // is_value_add: isValueAdd,
      }).then(() => {
        props.getItems();
        isPublishing ? setIsSubmitting(false) : setIsSaving(false);
        if (props.onSubmit) props.onSubmit();
      });
    } else {
      props.createItem({
        name,
        note,
        admin_note: adminNote,
        category: category.value,
        swap_designation: swap ? swap.value : null,
        condition: condition.value,
        unit,
        ...itemType === 'meals' ? {
          container_16oz: containers16oz,
          container_32oz: containers32oz,
          container_family: containersFamily,
          original_total_weight: getTotalWeightForMeals(containers16oz, containers32oz, containersFamily),
          weight_per_unit: 16,
          quantity_available: getTotalQuantityForMeals(containers16oz, containers32oz, containersFamily),
        } : {
          weight_per_unit: weightPerUnit,
          quantity_available: quantityAvailable,
          original_total_weight: originalTotalWeight,
        },
        use_by_date: useByDate,
        // supplier_id: supplierId ? supplierId.value : null,
        location_id: locationId ? locationId.value : null,
        published_at: isPublishing ? new Date() : null,
        is_on_hold: !isPublishing,
        // is_value_add: isValueAdd,
      }).then(() => {
        props.getItems();
        isPublishing ? setIsSubmitting(false) : setIsSaving(false);
        if (props.onSubmit) props.onSubmit();
      }).catch(() => {
        toast.error('error creating.')
      });
    }
  }

  const copy = () => {
    setIsCopying(true);
    props.copyItem({id: props.item.id}).then(() => {
      props.getItems();
      setIsCopying(false);
      if (props.onSubmit) props.onSubmit();
    })
  }

  const deleteItem = () => {
    if (confirm('Are you sure you want to delete this item?')) {
      if (confirm('Are you sure? This item will be removed from any reporting.')) {
        setIsDeleting(true);
        props.deleteItem({id: props.item.id}).then(() => {
          props.getItems();
          setIsDeleting(false);
          if (props.onSubmit) props.onSubmit();
        });
      }
    }
  }

  const onUnpublish = () => {
    setIsReverting(true);
    props.editItem({
      id: props.item.id,
      published_at: null,
      is_on_hold: true,
    }).then(() => {
      props.getItems();
      setIsReverting(false);
      if (props.onSubmit) props.onSubmit();
    }).catch(() => {
      toast.error('error unpublishing.')
    });
  }

  const createNewSupplier = (value) => {
    setIsSupplierListLoading(true);
    return props.createSupplier({
      name: value,
    }).then((action) => {
      return props.getSuppliers().then(() => {
        setSupplierId({ value: action.payload.data.id, label: action.payload.data.name });
        setIsSupplierListLoading(false);
      });
    }).catch(() => {
      toast.error('error creating.')
    });
  }

  const createNewLocation = (value) => {
    setIsLocationListLoading(true);
    return props.createLocation({
      name: value,
    }).then((action) => {
      return props.getLocations().then(() => {
        setLocationId({ value: action.payload.data.id, label: action.payload.data.name });
        setIsLocationListLoading(false);
      });
    }).catch(() => {
      toast.error('error creating.')
    });
  }

  const supplierOptions = props.suppliers.map(s => ({ value: s.id, label: s.name }));
  const locationOptions = props.locations.map(l => ({ value: l.id, label: l.name }));

  const editWeightPerUnit = (e) => {
    setWeightPerUnit(e.target.value);
  };
  const editQuantityAvailable = (e) => {
    setQuantityAvailable(e.target.value);
  };

  const ounceToPoundConversionPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ConversionTable />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="modal-body">
      <Form className="add-item-form" id="add-item-form">
        <h3>Information</h3>
        <Form.Group>
          <ButtonGroup>
            <ToggleButton
              type="radio"
              id="single-radio"
              variant={itemType === 'single' ? 'success' : 'outline-success'}
              name="itemTypeRadio"
              value="single"
              checked={itemType === 'single'}
              onChange={() => {
                setItemType('single');
                setCategory(categoryOptions[0]);
                setCondition(conditionOptions[0]);
                setUnit('');
              }}
            >
              Single
            </ToggleButton>

            <ToggleButton
              type="radio"
              id="meal-radio"
              variant={itemType === 'meals' ? 'success' : 'outline-success'}
              name="itemTypeRadio"
              value="meals"
              checked={itemType === 'meals'}
              onChange={() => {
                setItemType('meals');
                setCategory(categoryOptions.find(c => c.value === 'meals'));
                setCondition(conditionOptions.find(c => c.value === 'frozen'));
                setUnit('Container');
              }}
            >
              Meals
            </ToggleButton>
          </ButtonGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label>Name <span className="required">*</span></Form.Label>
          <Form.Control placeholder="Red Delicious Apples" value={name} onChange={e => setName(e.target.value)} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control placeholder="Sliced and bagged" value={note} onChange={e => setNote(e.target.value)} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Pick up by Date <span className="required">*</span></Form.Label>
          <Form.Control type="date" placeholder="10/30/23" value={useByDate} onChange={e => setUseByDate(e.target.value)} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Location</Form.Label>
          <Creatable
            isDisabled={isLocationListLoading}
            isLoading={isLocationListLoading}
            isClearable
            value={locationId}
            options={locationOptions}
            onChange={val => setLocationId(val)}
            onCreateOption={createNewLocation}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#0DBC57',
              colors: {
                ...theme.colors,
                primary25: '#D7ECD7',
                primary50: '#B3DBB4',
                primary: '#0DBC57',
              }
            })}
          />
        </Form.Group>

        {/* <Form.Group>
          <Form.Label>Supplier</Form.Label>
          <Creatable
            isDisabled={isSupplierListLoading}
            isLoading={isSupplierListLoading}
            isClearable
            value={supplierId}
            options={supplierOptions}
            onChange={val => setSupplierId(val)}
            onCreateOption={createNewSupplier}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#0DBC57',
              colors: {
                ...theme.colors,
                primary25: '#D7ECD7',
                primary50: '#B3DBB4',
                primary: '#0DBC57',
              }
            })}
          />
        </Form.Group> */}

        <h3>Groupings</h3>

        <Form.Group>
          <Form.Label>Category <span className="required">*</span></Form.Label>
          <Select
            isDisabled={category.value === 'meals'}
            value={category}
            options={categoryOptions.filter(c => c.value !== 'meals')}
            onChange={val => setCategory(val)}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#0DBC57',
              colors: {
                ...theme.colors,
                primary25: '#D7ECD7',
                primary50: '#B3DBB4',
                primary: '#0DBC57',
              }
            })}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>HER Nutrition</Form.Label>
          <Select
            isClearable
            value={swap}
            options={SWAPOptions}
            onChange={val => setSwap(val)}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#0DBC57',
              colors: {
                ...theme.colors,
                primary25: '#D7ECD7',
                primary50: '#B3DBB4',
                primary: '#0DBC57',
              }
            })}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Condition</Form.Label>
          <Select
            isClearable
            value={condition}
            options={conditionOptions}
            onChange={val => setCondition(val)}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#0DBC57',
              colors: {
                ...theme.colors,
                primary25: '#D7ECD7',
                primary50: '#B3DBB4',
                primary: '#0DBC57',
              }
            })}
          />
        </Form.Group>

        <h3>Units Available</h3>

        <OverlayTrigger trigger="click" placement="right" overlay={ounceToPoundConversionPopover}>
          <div className="btn btn-outline conversion-table-trigger" variant="success">Oz &rarr; Lb</div>
        </OverlayTrigger>

        <Form.Group>
          <Form.Label>Unit Type</Form.Label>
          <Form.Control placeholder="Bag" value={unit} onChange={e => setUnit(e.target.value)} />
        </Form.Group>

        <DangerZone item={props.item}>
          {itemType === 'single' && (
            <>
              <Form.Group>
                <Form.Label>Weight per Unit (lbs) <span className="required">*</span></Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="2" value={weightPerUnit} onChange={editWeightPerUnit} min={0} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Units Available <span className="required">*</span></Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="100" value={quantityAvailable} onChange={editQuantityAvailable} min={0} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Total Weight (lbs) <span className="required">*</span></Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="200" value={originalTotalWeight} onChange={e => setOriginalTotalWeight(e.target.value)} min={0} />
              </Form.Group>
            </>
          )}
          {itemType === 'meals' && (
            <>
              <Form.Group>
                <Form.Label>16 oz Containers (1 Serving)</Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="10" value={containers16oz} onChange={e => setContainers16oz(e.target.value)} min={0} />
              </Form.Group>

              <Form.Group>
                <Form.Label>32 oz Containers (2 Serving)</Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="10" value={containers32oz} onChange={e => setContainers32oz(e.target.value)} min={0} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Family Size Containers (6 Servings)</Form.Label>
                <Form.Control type="number" onWheel={ event => event.currentTarget.blur() } placeholder="10" value={containersFamily} onChange={e => setContainersFamily(e.target.value)} min={0} />
              </Form.Group>
            </>
          )}
        </DangerZone>

        {/* {(!props.item || props.item.is_on_hold) && (
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check checked={isValueAdd} type="checkbox" label="Is Value Add?" onChange={(e) => { setIsValueAdd(e.target.checked) }} />
          </Form.Group>
        )} */}

        <Form.Group>
          <Form.Label>Admin Note</Form.Label>
          <Form.Control as="textarea" rows={4} placeholder="Notes only admins can see..." value={adminNote} onChange={e => setAdminNote(e.target.value)} />
        </Form.Group>

        </Form>
      </div>

        <div className="modal-footer">
          <div className="btn-group">
            {<AnimatedButton form="add-item-form" isLoading={isSubmitting} onClick={() => submit(true)} className="btn">{props.item && !props.item.is_on_hold ? 'Save' : 'Publish'}</AnimatedButton>}
            {(!props.item || (props.item && !props.item.published_at)) && <AnimatedButton isLoading={isSaving} onClick={() => submit(false)} className="btn btn-outline">{props.item ? 'Save Draft' : 'Save as Draft'}</AnimatedButton>}
            {props.item && <AnimatedButton isLoading={isCopying} onClick={() => copy()} className="btn btn-outline">Duplicate</AnimatedButton>}
            <div className="btn btn-outline" onClick={props.onCancel}>Cancel</div>
            {props.item && props.item.is_on_hold && <AnimatedButton isLoading={isDeleting} onClick={() => deleteItem()} className="btn btn-danger">Delete</AnimatedButton>}
          </div>
          {(props.item && props.item.published_at) && <AnimatedButton isLoading={isReverting} className="btn btn-danger mb-0 float-end" onClick={() => onUnpublish()}>Unpublish</AnimatedButton>}
        </div>

    </>
  );
}

const mapStateToProps = state => ({
  suppliers: state.suppliers,
  locations: state.locations,
});
const mapDispatchToProps = {
  createItem: Api.createItem,
  createSupplier: Api.createSupplier,
  getSuppliers: Api.getSuppliers,
  createLocation: Api.createLocation,
  getLocations: Api.getLocations,
  getItems: Api.getItems,
  editItem: Api.editItem,
  copyItem: Api.copyItem,
  deleteItem: Api.deleteItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
