import { connect } from "react-redux"

const RequiresAdmin = (props) => {
  if (
    (props.user.is_admin && !props.reverse) ||
    (props.user.id && !props.user.is_admin && props.reverse)
  ) {
    return props.children;
  }

  return null;
}

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(RequiresAdmin);