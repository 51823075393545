import React from 'react';
import { Link } from 'react-router-dom';
import Auth from '../Lib/Auth';

export default (props) => {
  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Reports</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3>Reports</h3>
              <Link className="btn btn-outline mr-10" to="/reports/expired">Expired Items Report</Link>
              <Link className="btn btn-outline mr-10" to="/reports/admin-notes">Items with Admin Notes</Link>
              <Link className="btn btn-outline" to="/reports/locations">Edit Locations</Link>
            </div>
            <div className="col-12 hide">
              <h3>Downloadable Reports</h3>
              <a className="btn btn-outline mr-10" download href={`${process.env.API_HOST || 'http://localhost:8080/'}reports/orders/download?token=${Auth.getToken()}`}>YTD Orders CSV Report</a>
              <a className="btn btn-outline" download href={`${process.env.API_HOST || 'http://localhost:8080/'}reports/items/download?token=${Auth.getToken()}`}>YTD Items CSV Report</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
