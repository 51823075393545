import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import useKeyPress from '../../Hooks/useKeyPress';

import Api from '../../Lib/Api';
import AnimatedButton from '../AnimatedButton';


const Component = (props) => {
  const [name, setName] = useState(props.user ? props.user.name : '');
  const [email, setEmail] = useState(props.user ? props.user.email : '');
  const [phone, setPhone] = useState(props.user ? props.user.phone : '');
  const [password, setPassword] = useState('');
  const [isWeeklySubscribed, setIsWeeklySubscribed] = useState(props.user ? props.user.is_weekly_subscribed : true)
  const [isOrgPrimary, setIsOrgPrimary] = useState(props.user ? props.user.is_org_primary : false)
  const [organizationId] = useState(props.organization ? props.organization.id : null);
  const [isLoading, setIsLoading] = useState(false);

  const isValid = () => {
    if (!name || name.length === 0) return false;
    if (!email || email.length === 0) return false;

    return true;
  }

  useKeyPress(27, () => { if (props.onCancel) props.onCancel(); });

  const deleteUser = () => {
    if (confirm(`Are you sure you want to delete ${name}?`)) {
      if (confirm('This can not be undone. Are you sure?')) {
        props.deleteUser(props.user.id).then(() => {
          props.getOrganizations();
          if (props.onDelete) props.onDelete();
        });
      }
    }
  }

  const submit = () => {
    if (!isValid()) {
      toast.error('New users require "Name" and "Email" to be filled out.');
      return;
    }

    setIsLoading(true);

    if (props.user) {
      props.editUser({
        id: props.user.id,
        name,
        email,
        phone,
        password,
        is_weekly_subscribed: isWeeklySubscribed,
        is_org_primary: isOrgPrimary,
        organization_id: organizationId,
      }).then(() => {
        props.getOrganizations();
        setIsLoading(false);
        if (props.onSubmit) props.onSubmit();
      });
    } else {
      props.createUser({
        name,
        email,
        phone,
        password,
        is_weekly_subscribed: isWeeklySubscribed,
        is_org_primary: isOrgPrimary,
        organization_id: organizationId,
      }).then(() => {
        props.getOrganizations();
        setIsLoading(false);
        if (props.onSubmit) props.onSubmit();
      }).catch(() => {
        toast.error('error creating.')
      });
    }
  }


  return (
    <div>
      <Form className="add-item-form">
        <h4>Adding user to {props.organization && props.organization.name}.</h4>

        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control placeholder="Johnny B. Goode" value={name} onChange={e => setName(e.target.value)} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control autoComplete="off" type="email" placeholder="goodej@gmail.com" value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control type="phone" placeholder="5075555555" value={phone} onChange={e => setPhone(e.target.value)} />
        </Form.Group>

        {props.user && props.user.id === props.currentUser.id && (
          <Form.Group>
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" autoComplete="new-password" placeholder="1ts4S3cr3t" value={password} onChange={e => setPassword(e.target.value)} />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check checked={isOrgPrimary} type="checkbox" label="Is Organization's Primary Contact?" onChange={(e) => { setIsOrgPrimary(e.target.checked) }} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check checked={isWeeklySubscribed} type="checkbox" label="Is Subscribed to Weekly Emails?" onChange={(e) => { setIsWeeklySubscribed(e.target.checked) }} />
        </Form.Group>

        <div className="btn-group">
          <AnimatedButton isLoading={isLoading} onClick={() => submit()} className="btn">Save</AnimatedButton>
          <div className="btn btn-outline" onClick={props.onCancel}>Cancel</div>
        </div>
        {props.user && props.user.id && <div className="btn btn-danger float-end mb-0" onClick={() => deleteUser()}>Delete</div>}
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.user,
});
const mapDispatchToProps = {
  createUser: Api.createUser,
  getOrganizations: Api.getOrganizations,
  editUser: Api.editUser,
  deleteUser: Api.deleteUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
