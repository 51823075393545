export function getTotalWeightForMeals(containers16oz, containers32oz, containersFamily) {
    const count16 = containers16oz && Number.isSafeInteger(+containers16oz) ? parseInt(containers16oz, 10) : 0;
    const count32 = containers32oz && Number.isSafeInteger(+containers32oz) ? parseInt(containers32oz, 10) : 0;
    const countFamily = containersFamily && Number.isSafeInteger(+containersFamily) ? parseInt(containersFamily, 10) : 0;

    return count16 * 16 + count32 * 32 + countFamily * 96;
}

export function getTotalQuantityForMeals(containers16oz, containers32oz, containersFamily) {
    const count16 = containers16oz && Number.isSafeInteger(+containers16oz) ? parseInt(containers16oz, 10) : 0;
    const count32 = containers32oz && Number.isSafeInteger(+containers32oz) ? parseInt(containers32oz, 10) : 0;
    const countFamily = containersFamily && Number.isSafeInteger(+containersFamily) ? parseInt(containersFamily, 10) : 0;

    return count16 + count32 * 2 + countFamily * 6;
}

export function getMealLabel(type, short = false) {
    const mealLabels = {
        container_16oz: `16 oz ${!short ? '(1 serving)' : ''}`,
        container_32oz: `32 oz ${!short ? '(2 servings)' : ''}`,
        container_family: `Family Size ${!short ? '(6 servings)' : ''}`,
    };

    return mealLabels[type];
}