import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import Api from '../Lib/Api';
import AnimatedButton from '../Components/AnimatedButton';
import { toast } from 'react-toastify';

const Component = ({ organizations, currentUser, sendCustomMessage }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSendingToPrimaries, setIsSendingToPrimaries] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const availableUsers = organizations
    .reduce((l, o) => [...l, ...o.users.map(u => { u.org = o; return u; })], [])
    .filter(u => u.is_weekly_subscribed);

  const contactUsers = availableUsers.filter(u => u.is_org_primary);

  const onSubmit = () => {
    if (!isSendingToPrimaries && availableUsers.length === 0) return toast.error('No users to send to.');
    if (isSendingToPrimaries && contactUsers.length === 0) return toast.error('No primary contact users to send to.');

    setIsLoading(true);
    if (confirm(`Are you sure you want to send this message to ${isSendingToPrimaries ? contactUsers.length : availableUsers.length} ${isSendingToPrimaries ? 'primary contact' : ''} users.`)) {
      sendCustomMessage({ subject, message, primariesOnly: isSendingToPrimaries }).then((response) => {
        setIsLoading(false);
        setSubject('');
        setMessage('');
        toast.success(`Message sent.`);
      });
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Custom Messaging</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="btn-group">
                <div onClick={() => setIsSendingToPrimaries(true)} className={`btn btn-small ${!isSendingToPrimaries ? 'btn-outline' : ''}`}>Primary Contacts Only</div>
                <div onClick={() => setIsSendingToPrimaries(false)} className={`btn btn-small ${isSendingToPrimaries ? 'btn-outline' : ''}`}>All Users</div>
              </div>
              <div className="recipients-list">
                {(isSendingToPrimaries ? contactUsers : availableUsers)
                  .sort((a, b) => a.org.name.toLowerCase() > b.org.name.toLowerCase() ? 1 : -1)
                  .map(u => (
                    <Badge
                      className="clipboard-badges"
                      onClick={() => navigator.clipboard.writeText(u.email)}
                      title={`Copy '${u.email}' to clipboard`}
                      key={u.id}
                      bg="secondary"
                    >
                      {`${u.name} (${u.org.name})`}
                    </Badge>
                  ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form className="send-message-form">
                <Form.Group>
                  <Form.Label>Subject:</Form.Label>
                  <Form.Control placeholder="Email Subject..." value={subject} onChange={e => setSubject(e.target.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Message:</Form.Label>
                  <Form.Control as="textarea" placeholder="Your Message..." value={message} onChange={e => setMessage(e.target.value)} className="custom-message-textarea" />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Sent by: {currentUser.name}</Form.Label>
                </Form.Group>

                <div className="btn-group">
                  <AnimatedButton isLoading={isLoading} onClick={onSubmit} className="btn mb-0">Send</AnimatedButton>
                  <div className="btn btn-outline mb-0" onClick={onCancel}>Cancel</div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = state => ({
  organizations: state.organizations,
  currentUser: state.user,
});
const mapDispatchToProps = {
  sendCustomMessage: Api.sendCustomMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);

