import React from 'react';
import InventoryItem from './InventoryItem';

export default (props) => {
  return (
    <section className="category-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h3 className="category-heading">{props.label}</h3>
          </div>
          <div className="col-12">
            <div className="category-container">

              <div className="food-item column-headings">
                <div className="food-item-content">
                  <div className="item-name-description">Name</div>
                  <div className="item-location">Location</div>
                  <div className="item-condition">Condition</div>
                  <div className="item-use-by">Pick Up By</div>
                  {props.label !== 'meals' && (<div className="item-unit-weight">Weight per Unit</div>)}
                  <div className={props.label == 'meals' ? 'meal-item-list' : 'item-units-available'}>Units Available</div>
                  <div className="item-actions"></div>
                </div>
              </div>

              {props.items.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(item => (
                <InventoryItem
                  key={item.id}
                  item={item}
                  onEditClicked={props.onEditClicked}
                  onMarkAsClicked={props.onMarkAsClicked}
                />
              ))}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}