import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select'
import { toast } from 'react-toastify';

import Api from '../../Lib/Api';
import useKeyPress from '../../Hooks/useKeyPress';
import AnimatedButton from '../AnimatedButton';

const itemChangeOptions = [
  { value: 'claimed_by', label: 'Claimed By' },
  { value: 'compost', label: 'Compost' },
  { value: 'livestock_feed', label: 'Livestock Feed' },
  { value: 'waste', label: 'Waste' },
  // { value: 'value_add', label: 'Value Add - Preserve, Store, Meals' },
  // { value: 'value_add_dehydrate', label: 'Value Add - Dehydrate' },
  { value: 'ws_trade', label: 'Wooden Spoon Trade' },
];

const Component = ({ item, organizations, onSubmit, onCancel, getItems, getOrders, createItemChange }) => {
  const [changeType, setChangeType] = useState(itemChangeOptions[0]);
  const [organization, setOrganization] = useState('');
  const [quantity, setQuantity] = useState('');
  const [quantity16, setQuantity16] = useState('');
  const [quantity32, setQuantity32] = useState('');
  const [quantityFamily, setQuantityFamily] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const organizationOptions = organizations.map(o => ({ value: o.id, label: o.name }));

  useKeyPress(27, () => { if (onCancel) onCancel(); });

  const isDisabled = useCallback(() => {
    const total = item.category === 'meals' ? +quantity16 + +quantity32 + +quantityFamily : quantity;
    if (changeType.value === 'claimed_by') return !(organization.value && total !== 0);
    return !(total !== 0);
  }, [item, changeType, organization, quantity, quantity16, quantity32, quantityFamily]);

  const submit = useCallback(() => {
    if (parseInt(quantity) < 0) return toast.error('Quantity must be greater than 0');

    setIsLoading(true);
    createItemChange({
      ...item.category === 'meals' ? {
        container_16oz: +quantity16 || 0,
        container_32oz: +quantity32 || 0,
        container_family: +quantityFamily || 0,
      } : { quantity: +quantity || 0 },
      change_type: changeType.value,
      item_id: item.id,
      organization_id: organization.value,
    }).then(() => {
      getItems();
      if (changeType.value === 'claimed_by') getOrders();
      setIsLoading(false);
      onSubmit();
    });
  }, [changeType, item, organization, quantity, quantity16, quantity32, quantityFamily]);

  const renderQuantityLabel = () => {
    switch (changeType.value) {
      case 'claimed_by':
        return 'Quantity Claimed'
      case 'compost':
        return 'Quantity Composted';
      case 'livestock_feed':
        return 'Quantity Converted to Feed';
      case 'waste':
        return 'Quantity Thrown Out';
      case 'value_add':
        return 'Quantity Converted';
      default:
        return 'Quantity Changed';
    }
  }

  const renderSubmitLabel = () => {
    switch (changeType.value) {
      case 'claimed_by':
        return 'Mark as Claimed'
      case 'compost':
        return 'Mark as Composted';
      case 'livestock_feed':
        return 'Mark as Livestock Feed';
      case 'waste':
        return 'Mark as Waste';
      case 'value_add':
        return 'Mark as Value Add';
      default:
        return 'Submit';
    }
  }

  return (
    <>
      <div className="modal-body" style={{ minHeight: 420 }}>
        <h3>{item.name}</h3>
        <h4>{item.note}</h4>
        <Form className="add-item-form">
          <Form.Group>
            <Form.Label>Mark as...</Form.Label>
            <Select
              value={changeType}
              options={itemChangeOptions}
              onChange={val => setChangeType(val)}
              theme={theme => ({
                ...theme,
                borderRadius: 6,
                borderColor: '#0DBC57',
                colors: {
                  ...theme.colors,
                  primary25: '#D7ECD7',
                  primary: '#0DBC57',
                }
              })}
            />
          </Form.Group>

          {changeType.value === 'claimed_by' && (
            <Form.Group>
              <Form.Label>Organization</Form.Label>
              <Select
                value={organization}
                options={organizationOptions}
                onChange={val => setOrganization(val)}
                theme={theme => ({
                  ...theme,
                  borderRadius: 6,
                  borderColor: '#0DBC57',
                  colors: {
                    ...theme.colors,
                    primary25: '#D7ECD7',
                    primary: '#0DBC57',
                  }
                })}
              />
            </Form.Group>
          )}

          {item.category === 'meals' ? (
            <div>
              <Form.Group>
                <Form.Label>16oz {renderQuantityLabel()} ({item.container_16oz} available)</Form.Label>
                <Form.Control type="number" pattern="[0-9]*" placeholder="0" value={quantity16} onChange={e => setQuantity16(e.target.value)} min={1} max={item.container_16oz} />
              </Form.Group>
              <Form.Group>
                <Form.Label>32oz {renderQuantityLabel()} ({item.container_32oz} available)</Form.Label>
                <Form.Control type="number" pattern="[0-9]*" placeholder="0" value={quantity32} onChange={e => setQuantity32(e.target.value)} min={1} max={item.container_32oz} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Family Size {renderQuantityLabel()} ({item.container_family} available)</Form.Label>
                <Form.Control type="number" pattern="[0-9]*" placeholder="0" value={quantityFamily} onChange={e => setQuantityFamily(e.target.value)} min={1} max={item.container_family} />
              </Form.Group>
            </div>
          ) : (
            <Form.Group>
              <Form.Label>{renderQuantityLabel()} ({item.quantity_available} available)</Form.Label>
              <Form.Control type="number" pattern="[0-9]*" placeholder="0" value={quantity} onChange={e => setQuantity(e.target.value)} min={1} max={item.quantity_available} />
            </Form.Group>
          )}


        </Form>
      </div>
      <div className="modal-footer">
        <div className="btn-group">
          <AnimatedButton isLoading={isLoading} disabled={isDisabled()} onClick={submit} className="btn">{renderSubmitLabel()}</AnimatedButton>
          <div className="btn btn-outline" onClick={onCancel}>Cancel</div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  organizations: state.organizations,
});
const mapDispatchToProps = {
  createItemChange: Api.createItemChange,
  getItems: Api.getItems,
  getOrders: Api.getOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
