import { createStore, applyMiddleware } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';

import Action from './Action';
import Auth from './Auth';

const initialState = {
  user: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) || {},
  items: [],
  orders: [],
  organizations: [],
  suppliers: [],
  cart: sessionStorage.getItem('cart') && JSON.parse(sessionStorage.getItem('cart')) || [],
  options: localStorage.getItem('options') && JSON.parse(localStorage.getItem('options')) || { kioskMode: false},
  adminSettings: {},
};

const defaultReducers = (state = initialState, action) => {
  if (action.type.endsWith('SUCCESS')) {
    const message = [`[${action.payload.status}]`, action.payload.config.method, action.payload.config.url, action.payload.data];
    console.debug(...message);
  } else if (action.type.endsWith('FAIL')) {
    const message = [`[${action.error.response.status}]`, action.error.config.method, action.error.config.url, action.error.message]
    console.error(...message)
  } else if (Object.keys(Action).includes(action.type)) {
    const message = [`[OUT]`, action.payload.request.method, action.payload.request.url, action.payload.request.method === 'post' ? action.payload.request.data : action.payload.request.params];
    console.debug(...message);
  }

  switch (action.type) {
    case 'UPDATE_CART':
      return { ...state, cart: action.payload };
    case Action.AUTHENTICATE_SUCCESS:
    case Action.REFRESH_TOKEN_SUCCESS:
      Auth.storeToken(action.payload.data.token);
      Auth.storeUser(action.payload.data.user);
      return { ...state, user: action.payload.data.user };
    case Action.GET_ITEMS_SUCCESS:
      if (action.meta.previousAction.payload.request.params.expired) {
        return { ...state } // don't save expired items requests
      }
      return { ...state, items: action.payload.data };
    case Action.GET_ORDERS_SUCCESS:
      return { ...state, orders: action.payload.data };
    case Action.GET_ORGANIZATIONS_SUCCESS:
      return { ...state, organizations: action.payload.data };
    case Action.GET_SUPPLIERS_SUCCESS:
      return { ...state, suppliers: action.payload.data };
    case Action.GET_LOCATIONS_SUCCESS:
      return { ...state, locations: action.payload.data };
    case Action.GET_SETTINGS_SUCCESS:
      return { ...state, adminSettings: action.payload.data };
    default:
      return { ...state };
  }
}

const baseURL = process.env.API_HOST ? process.env.API_HOST : 'http://localhost:8080';
export const api = axios.create({ baseURL });

const store = createStore(defaultReducers, applyMiddleware(axiosMiddleware(api)));

export default store;
